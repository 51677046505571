import "./App.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxJWFtyCzU7T0aWHFJgoR_5_ibmuUEDVk",
  authDomain: "godroomdevelopments-400102.firebaseapp.com",
  projectId: "godroomdevelopments-400102",
  storageBucket: "godroomdevelopments-400102.appspot.com",
  messagingSenderId: "238214479802",
  appId: "1:238214479802:web:8495c9e33353270feb2783",
  measurementId: "G-32ZBC6K9VB"
};


function App() {

  // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

  return (
    <div className="App">
      <body>
        <div className="container">
          <h1 className="main_text">God Room Developments</h1>
          <span className="subtext">
            contact@godroomdevelopments.com | Only after verifying your identity, you will here from us.
          </span>
          <span className="barcode">we develop robust tech asked by our clients - (*any kind) .Every thing depends on money</span>
        </div>
      </body>

      <div style={{height:1000, width:"100%", color:"white"}}></div>
    </div> 
  );
}

export default App;
